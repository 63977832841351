import React from 'react'

const PinkOval = ({className}) => (
  <svg viewBox="0 0 100 100" preserveAspectRatio="xMinYMin" className={'pink-oval ' + className} aria-hidden="true">
    <defs>
      <filter id="pink-oval-shadow">
          <feGaussianBlur in="SourceGraphic" stdDeviation="10" />
          <feOffset dy="-10" dx="-10" />
          <feComposite in2="SourceGraphic" operator="arithmetic" k2="-1" k3="1" result="firstShadow" />
          <feFlood floodColor="#F2958E" />
          <feComposite in2="firstShadow" operator="in" />
          <feComposite in2="SourceGraphic" operator="over" result="firstShadow" />
      </filter>
    </defs>
    <circle cx="50" cy="50" r="50" filter="url(#pink-oval-shadow)" fill="#FBDCD8" />
  </svg>
)

export default PinkOval;