import React, { useContext } from 'react'
import GlobalState from '../context'
import RssIcon from '../assets/rss-icon.svg';

const Footer = () => {
  const { siteTitle } = useContext(GlobalState)

  return (
    <footer className="global">
      &copy; {new Date().getFullYear()} {siteTitle}
      <a href="/rss.xml"><RssIcon /><abbr title="Really Simple Syndication">RSS</abbr>&nbsp;Feed</a>
    </footer>
  )
}

export default Footer