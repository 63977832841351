import React from 'react'

const MagentaRectangle = ({className}) => (
  <svg viewBox="0 0 100 100" preserveAspectRatio="xMinYMin" className={'magenta-rectangle ' + className} aria-hidden="true">
    <defs>
      <filter id="magenta-rectangle-shadow">
          <feGaussianBlur in="SourceAlpha" stdDeviation="23" />
          <feOffset dy="5" dx="5" result="firstOffsetBlur" />
          <feComposite in2="SourceAlpha" operator="arithmetic" k2="-1" k3="1" result="shadowDiff" />
          <feFlood floodColor="#D067B5" />
          <feComposite in2="shadowDiff" operator="in" />
          <feComposite in2="SourceGraphic" operator="over" result="firstShadow" />

          <feGaussianBlur in="firstShadow" stdDeviation="23" />
          <feOffset dy="-5" dx="-5" />
          <feComposite in2="firstShadow" operator="arithmetic" k2="-1" k3="1" result="shadowDiff" />
          <feFlood floodColor="#D067B5" />
          <feComposite in2="shadowDiff" operator="in" />
          <feComposite in2="firstShadow" operator="over" />
      </filter>
    </defs>
    <rect width="100%" height="50%" filter="url(#magenta-rectangle-shadow)" fill="#E7C3F2"/>
  </svg>
)

export default MagentaRectangle