import React, { useContext } from 'react'
import { TransitionGroup, Transition } from 'react-transition-group'

import GlobalState from '../context'

const PageTransition = ({children}) => {
  console.log({children})
  const {route} = useContext(GlobalState)
  const timeout = 500

  const getTransitionStyles = {
    entering: {
      position: `absolute`,
      opacity: 0,
    },
    entered: {
      transition: `opacity ${timeout}ms ease-in`,
      opacity: 1,
    },
    exiting: {
      transition: `opacity 300ms ease-out`,
      opacity: 0,
    },
  }

  let path = route.pathname

  let className = ''

  if(path === '/') className = 'home'
  if(path.startsWith('/article')) className = 'single-article'
  if(path.startsWith('/archives')) className = 'archives'

  return (
    <TransitionGroup>
      <Transition key={path} timeout={{enter: timeout, exit: timeout}}>
        {status => <main className={[status, className].join(' ')} style={{...getTransitionStyles[status]}}>{children}</main>}
      </Transition>
    </TransitionGroup>
  )
} 

export default PageTransition