import React, { useState } from 'react'
import GlobalState from '../context'
import { useStaticQuery, graphql } from 'gatsby'
import PageTransition from './PageTransition'

import Footer from './Footer'
import Header from './Header'

const Layout = ({ location: route, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`

  const [pageName, setPageName] = useState(null)

  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  )

  const siteTitle = data.site.siteMetadata?.title

  const state = {
    pageName,
    setPageName,
    siteTitle,
    route,
    rootPath
  }

  return (
    <GlobalState.Provider value={state}>
      <Header route={route} />
      <PageTransition>
        {children}
      </PageTransition>
      <Footer />
    </GlobalState.Provider>
  )
}

export default Layout
