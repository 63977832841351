import React from 'react'

const CyanTriangle = ({className}) => (
  <svg viewBox="0 0 100 100" preserveAspectRatio="xMinYMin" className={'cyan-triangle ' + className} aria-hidden="true">
    <defs>
      <filter id="cyan-triangle-shadow">
        <feGaussianBlur in="SourceAlpha" stdDeviation="30" result="blur" />
        <feComposite in2="SourceAlpha" operator="arithmetic" k2="-1" k3="1" result="shadowDiff" />
        <feFlood floodColor="#68C6CC" />
        <feComposite in2="shadowDiff" operator="in" />
        <feComposite in2="SourceGraphic" operator="over" result="firstShadow" />

        <feGaussianBlur in="firstShadow" stdDeviation="15" />
        <feOffset dy="-7" dx="-12" />
        <feComposite in2="firstShadow" operator="arithmetic" k2="-1" k3="1" result="shadowDiff" />
        <feFlood floodColor="#68C6CC" />
        <feComposite in2="shadowDiff" operator="in" />
        <feComposite in2="firstShadow" operator="over" />
      </filter>
    </defs>
    <polygon points="50 20, 100 100, 0 100" filter="url(#cyan-triangle-shadow)" fill="#C8F1F3" />
  </svg>
)

export default CyanTriangle